import { MediaType } from "@evercam/shared/types"

export const MediaTypes = [
  {
    translationKey: "content.media_hub.media_type.clip",
    icon: "fas fa-cloud",
    value: MediaType.Clip,
  },
  {
    translationKey: "content.media_hub.media_type.local_clip",
    icon: "fas fa-hard-drive",
    value: MediaType.LocalClip,
  },
  {
    translationKey: "content.media_hub.media_type.compare",
    iosIcon: "fas fa-compress",
    icon: "fas fa-left-right",
    value: MediaType.Compare,
  },
  {
    translationKey: "content.media_hub.media_type.url",
    icon: "fas fa-link",
    value: MediaType.ExternalUrl,
  },
  {
    translationKey: "content.media_hub.media_type.file",
    icon: "fas fa-file-arrow-up",
    value: MediaType.File,
  },
  {
    translationKey: "content.media_hub.media_type.xray",
    icon: "fas fa-x-ray",
    value: MediaType.XRay,
  },
  {
    translationKey: "content.media_hub.media_type.mark_up",
    icon: "fas fa-images",
    value: MediaType.EditedImage,
  },
  {
    translationKey: "content.media_hub.media_type.timelapse",
    iosIcon: "fas fa-clock",
    icon: "fas fa-history",
    value: MediaType.Timelapse,
  },
  {
    translationKey: "content.media_hub.media_type.mobile",
    icon: "fas fa-mobile-screen-button",
    value: MediaType.MobileAsset,
  },
]
