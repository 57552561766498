
import Vue, { PropType } from "vue"
import { axios } from "@evercam/shared/api/axios"

export default Vue.extend({
  name: "PdfViewer",
  props: {
    mediaUrl: {
      type: String,
      required: true,
    },
    params: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      pdfDataUrl: null,
      pdfDoc: null,
    }
  },
  watch: {
    mediaUrl: {
      handler: "loadPdf",
      immediate: true,
    },
  },
  async mounted() {
    await this.loadPdf()
  },

  methods: {
    async loadPdf() {
      try {
        this.loading = true
        this.error = null

        const response = await axios.get(this.mediaUrl, {
          params: this.params,
          responseType: "blob",
        })
        const blob = new Blob([response], { type: "application/pdf" })
        this.pdfDataUrl = URL.createObjectURL(blob)

        if (this.needsBase64) {
          const reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = () => {
            this.pdfDataUrl = reader.result
          }
        }
      } catch (err) {
        console.error("Error loading PDF:", err)
        this.error = "Failed to load PDF. Please try again."
      } finally {
        this.loading = false
      }
    },
  },
})
