
import Vue, { PropType } from "vue"
import Papa from "papaparse"
import { axios } from "@evercam/shared/api/axios"

export default Vue.extend({
  name: "ECSVViewer",
  props: {
    mediaUrl: {
      type: String,
      required: true,
    },
    skipRows: {
      type: Number,
      default: 0,
    },
    params: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      parsedCsvData: [] as any[],
      isError: false,
      error: null as string | null,
    }
  },
  watch: {
    mediaUrl: {
      immediate: true,
      handler: "loadCsvContent",
    },
  },
  methods: {
    async loadCsvContent() {
      try {
        const response = await axios.get(this.mediaUrl, {
          params: {
            ...this.params,
          },
          responseType: "blob",
        })
        // Read blob as text
        const text = await new Response(response).text()
        // Parse CSV
        Papa.parse(text, {
          complete: (results) => {
            if (results.data.length > 0) {
              this.parsedCsvData = results.data.slice(this.skipRows)
              this.error = null
            }
          },
          error: (error) => {
            this.error = "Failed to parse CSV. Please try again."
            console.error("Error loading CSV:", error)
            this.$emit("error", error)
          },
        })
      } catch (error) {
        this.error = "Failed to load CSV. Please try again."
        console.error("Error loading CSV:", error)
        this.$emit("error", error)
      }
    },
  },
})
