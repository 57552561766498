
import MediaShareDialog from "@evercam/shared/components/medias/MediaShareDialog"
import IosMediaDialog from "@evercam/shared/components/medias/IosMediaDialog"
import DropDownButton from "@evercam/shared/components/DropDownButton"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import {
  getConvertedUtcDateTimetoTimezone,
  getIOSMediaUrl,
} from "@evercam/shared/utils"
import { useAccountStore } from "@/stores/account"
import { useMediaHubStore } from "@/stores/mediaHub"

import {
  Media,
  MediaFileType,
  MediaType,
  MediaUpdateRequestPayload,
} from "@evercam/shared/types/media"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"
import { Camera } from "@evercam/shared/types"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import MediaTypeBadge from "@evercam/shared/components/medias/MediaTypeBadge.vue"
import { navigateTo } from "#app"

export default Vue.extend({
  name: "MediaOverviewFooter",
  components: {
    MediaShareDialog,
    IosMediaDialog,
    DropDownButton,
    MediaTypeBadge,
  },
  props: {
    mediaItem: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
    mediaUrl: {
      type: String,
      default: "",
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDownload: false,
      iosMediaDialog: false,
      iosMediaUrl: "",
      formats: [
        {
          name: "Video (*.mp4)",
          value: "mp4",
          icon: this.$device.isIos ? "fas fa-video" : "fa-file-video",
        },
        {
          name: "GIF (*.gif) ",
          value: "gif",
          icon: "fa-repeat",
        },
      ],
      isPublic: false as boolean | undefined,
      canEditTitle: false,
      isIosMp4Extension: false,
      showDownloadProgress: false,
      downloadPercentage: 0,
      mediaTitle: "",
      fileTypes: {
        [MediaType.Clip]: "Clip",
        [MediaType.LocalClip]: "Local Clip",
        [MediaType.Compare]: "Compare",
        [MediaType.ExternalUrl]: "URL",
        [MediaType.EditedImage]: "Mark-up",
        [MediaType.XRay]: "XRay",
        [MediaType.File]: "File",
        [MediaType.Timelapse]: "Timelapse",
      } as Record<MediaType, string>,
    }
  },
  computed: {
    ...mapStores(
      useAccountStore,
      useProjectStore,
      useCameraStore,
      useMediaHubStore
    ),
    requesterName(): string {
      return this.mediaItem.requesterName
        .split(" ")
        .map((name) => name[0].toUpperCase())
        .join("")
    },
    selectedCamera(): Camera {
      return this.cameraStore.cameras.find(
        (c) => c.id === this.mediaItem.cameraExid
      )
    },
    mediaProjectExid(): string {
      return this.$route.params.project_exid
    },
    fileType(): string {
      return this.fileTypes[this.mediaType] || ""
    },
    isCompareMediaType(): boolean {
      return this.mediaType === MediaType.Compare
    },
    isExternalUrlMediaType(): boolean {
      return this.mediaType === MediaType.ExternalUrl
    },
    showPublic(): boolean {
      return (
        this.accountStore.token &&
        this.$permissions.project.has.socialMediaSharing() &&
        this.mediaType !== MediaType.Compare
      )
    },
    mediaType(): MediaType {
      return this.mediaItem?.type
    },
    showIntervalDates(): boolean {
      return [
        MediaType.Compare,
        MediaType.Timelapse,
        MediaType.Clip,
        MediaType.LocalClip,
      ].includes(this.mediaItem.type)
    },
  },
  mounted() {
    this.mediaTitle = this.mediaItem.title
    this.isPublic = this.mediaItem?.public
    this.showDownload = this.mediaType !== MediaType.ExternalUrl
  },
  methods: {
    getConvertedUtcDateTimetoTimezone,
    async exportAs({ value: format }) {
      this.downloadItem({
        url: this.mediaUrl.replace(/mp4.*/gi, format),
        format,
        type: this.mediaItem.type,
      })
    },
    getLinkPath() {
      return this.projectStore.selectedProject
        ? `${this.projectStore.projectRoute}/media-hub`
        : "/v2/projects"
    },
    changePublic() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaTogglePublicVisibility, {
        publiclyAccessible: this.isPublic,
      })
      const data: MediaUpdateRequestPayload = {
        public: this.isPublic,
      }
      let controller
      if (this.mediaType === MediaType.Timelapse) {
        controller = EvercamApi.timelapse
      } else {
        controller = EvercamApi.mediaHub
      }

      controller
        .cUpdate(this.mediaProjectExid, this.mediaItem.exid, data)
        .then(() => {
          this.$notifications.success(
            this.$t(
              `content.media_hub.${
                this.isPublic ? "make_public_success" : "make_private_success"
              }`
            )
          )
        })
        .catch((error: Error) => {
          this.$notifications.error({
            text: this.$t(
              `content.media_hub.${
                this.isPublic ? "make_public_error" : "make_private_error"
              }`
            ),
            error,
          })
          this.isPublic = !this.isPublic
        })
    },
    cancelTitleEditing() {
      this.mediaTitle = this.mediaItem.title
      this.toggleTitleEditing()
    },
    toggleTitleEditing() {
      this.canEditTitle = !this.canEditTitle
      this.$analytics.saveEvent(AnalyticsEvent.MediaToggleTitleEditing, {
        visible: this.canEditTitle,
      })
    },
    updateMedia(payload: MediaUpdateRequestPayload) {
      let controller
      if (this.mediaType === MediaType.Compare) {
        controller = EvercamApi.compares
      } else if (this.mediaType === MediaType.Timelapse) {
        controller = EvercamApi.timelapse
      } else {
        controller = EvercamApi.mediaHub
      }

      return controller.cUpdate(
        this.mediaProjectExid,
        this.mediaItem.exid,
        payload
      )
    },
    updateTitle() {
      this.toggleTitleEditing()
      this.$analytics.saveEvent(AnalyticsEvent.MediaSaveTitle)
      this.updateMedia({
        title: this.mediaTitle,
        name: this.mediaTitle,
      })
        .then(() => {
          this.$notifications.success(
            this.$t("content.media_hub.title_edit_success")
          )
        })
        .catch((error: Error) => {
          this.$notifications.error({
            text: this.$t("content.media_hub.title_edit_error"),
            error,
          })
        })
    },
    async downloadItem({
      url,
      type,
      format = null,
    }: {
      url: string
      type?: MediaType
      format?: MediaFileType | null
    }) {
      let extension = url.includes(MediaFileType.Pdf)
        ? MediaFileType.Pdf
        : format || MediaFileType.Mp4
      if (
        [MediaType.EditedImage, MediaType.XRay]?.includes(type as MediaType)
      ) {
        extension = url.includes(MediaFileType.Png)
          ? MediaFileType.Png
          : MediaFileType.Jpeg
      }

      this.$analytics.saveEvent(AnalyticsEvent.MediaDownloadAs, {
        type,
        downloadAs: extension,
      })
      if (
        this.$device.isIos &&
        !this.$device.isChrome &&
        !this.$device.isSafari
      ) {
        this.iosMediaUrl =
          extension === MediaFileType.Mp4
            ? window.location.href
            : getIOSMediaUrl(url)
        this.isIosMp4Extension = extension === MediaFileType.Mp4
        this.iosMediaDialog = true

        return
      }
      try {
        this.showDownloadProgress = true
        const response = await this.$axios.get(url, {
          params: {
            download: true,
          },
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            this.showDownloadProgress = progressEvent.lengthComputable
            this.downloadPercentage = percentCompleted
          },
        })
        this.isIosMp4Extension = false
        this.downloadPercentage = 0
        const fileName = `${this.mediaTitle}.${extension}`
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(response, fileName)
        }
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(response)
        link.download = fileName
        link.click()
      } catch (error) {
        this.downloadPercentage = 0
        console.error(error)
      } finally {
        this.showDownloadProgress = false
      }
    },
    formatDate(date: string): string {
      return this.$moment
        .tz(date, this.projectStore.selectedProjectTimezone)
        .format("Do MMM, YYYY | LT")
    },
    deleteMedia() {
      let controller
      if (this.mediaItem.type === MediaType.Compare) {
        controller = EvercamApi.compares
      } else if (this.mediaItem.type === MediaType.Timelapse) {
        controller = EvercamApi.timelapse
      } else {
        controller = EvercamApi.mediaHub
      }

      return controller?.cDelete(
        this.projectStore.selectedProjectExid,
        this.mediaItem.exid
      )
    },
    async openDeleteDialog() {
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_media"),
          message: this.$t("content.media_hub.delete_media"),
        })
      ) {
        this.handleDelete()
      }
    },
    handleDelete() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaDelete, {
        type: this.mediaItem.type,
      })
      this.deleteMedia()
        .then(() => {
          this.mediaHubStore.forceReloadMediaHub()
          this.$notifications.success(
            this.$t("content.media_hub.delete_success")
          )
          this.goBack()
        })
        .catch((error) => {
          this.$notifications.error({
            text: this.$t("content.media_hub.delete_error"),
            error,
          })
        })
    },
    goBack() {
      this.cameraStore.selectedCamera = null
      this.$analytics.saveEvent(AnalyticsEvent.MediaGoToMediaHub, {
        type: this.mediaItem.type,
      })

      return navigateTo(
        `${this.projectStore.projectRoute}/media-hub${
          this.mediaHubStore.filterParamsStringify
            ? `?${this.mediaHubStore.filterParamsStringify}`
            : ""
        }`
      )
    },
  },
})
