
import { Media } from "@evercam/shared/types/media"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { navigateTo } from "#app"

export default Vue.extend({
  name: "MediaOverviewHeader",
  props: {
    mediaItem: {
      type: Object as PropType<Media>,
      default: () => ({} as Media),
    },
  },
  computed: {
    ...mapStores(useMediaHubStore, useProjectStore, useCameraStore),
  },
  methods: {
    goBack() {
      this.cameraStore.selectedCamera = null
      this.$analytics.saveEvent(AnalyticsEvent.MediaGoToMediaHub, {
        type: this.mediaItem.type,
      })

      return navigateTo(
        `${this.projectStore.projectRoute}/media-hub${
          this.mediaHubStore.filterParamsStringify
            ? `?${this.mediaHubStore.filterParamsStringify}`
            : ""
        }`
      )
    },
  },
})
